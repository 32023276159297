<template>
  <main class="skv-academy">
    <section class="skv-academy__banner">
      <div class="skv-academy__banner-top">
        <div class="content">
          <div class="skv-academy__banner-container">
            <div class="skv-academy__banner-text-container">
              <div class="skv-academy__banner-title">Академия СКВ</div>
              <div class="skv-academy__banner-description">
                Меняя взгляд на волчанку
                <br /><br />
                Научно-информационный проект, посвященный современным подходам
                к ведению пациентов с системной красной волчанкой
              </div>
            </div>
            <div class="skv-academy__banner-links">
              <div class="skv-academy__banner-links_row">
                <a
                  href="#video-1"
                  @click.prevent="scrollTo('#video-1')"
                  class="skv-academy__banner-links_item"
                >
                  <div class="skv-academy__banner-links_title">Смотреть</div>
                  <div class="skv-academy__banner-links_link">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_156_1903)">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="#EBEFEE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14 9.33948L20 15.3395L14 21.3395"
                          fill="#830051"
                        />
                        <path
                          d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                          stroke="#830051"
                          stroke-width="2"
                          stroke-linecap="square"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_156_1903">
                          <rect
                            y="0.339478"
                            width="30"
                            height="30"
                            rx="15"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Модуль 1</span>
                  </div>
                </a>
                <a
                  href="#video-2"
                  @click.prevent="scrollTo('#video-2')"
                  class="skv-academy__banner-links_item"
                >
                  <div class="skv-academy__banner-links_title">Смотреть</div>
                  <div class="skv-academy__banner-links_link">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_156_1903)">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="#EBEFEE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14 9.33948L20 15.3395L14 21.3395"
                          fill="#830051"
                        />
                        <path
                          d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                          stroke="#830051"
                          stroke-width="2"
                          stroke-linecap="square"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_156_1903">
                          <rect
                            y="0.339478"
                            width="30"
                            height="30"
                            rx="15"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Модуль 2</span>
                  </div>
                </a>
                <a
                  href="#video-3"
                  @click.prevent="scrollTo('#video-3')"
                  class="skv-academy__banner-links_item"
                >
                  <div class="skv-academy__banner-links_title">Смотреть</div>
                  <div class="skv-academy__banner-links_link">
                    <svg
                      width="30"
                      height="31"
                      viewBox="0 0 30 31"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clip-path="url(#clip0_156_1903)">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="#EBEFEE"
                        />
                        <path
                          fill-rule="evenodd"
                          clip-rule="evenodd"
                          d="M14 9.33948L20 15.3395L14 21.3395"
                          fill="#830051"
                        />
                        <path
                          d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                          stroke="#830051"
                          stroke-width="2"
                          stroke-linecap="square"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_156_1903">
                          <rect
                            y="0.339478"
                            width="30"
                            height="30"
                            rx="15"
                            fill="white"
                          />
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Модуль 3</span>
                  </div>
                </a>
              </div>
            </div>
          </div>
        </div>
        <div class="skv-academy__banner-image_container">
          <picture
            ><source
              :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/skv(mob).png
              `"
              media="(max-width: 767px)"
            />
            <source
              :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/skv(tab).png
              `"
              media="(max-width: 1220px)"
            />
            <source
              :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/skv.png
              `"
              media="(max-width: 1919px)"
            />
            <img
              :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/skv.png`"
              class="skv-academy__banner-image"
            />
          </picture>
        </div>
        <div class="skv-academy__banner-links_mob">
          <div class="content">
            <div class="skv-academy__banner-links_row">
              <a
                href="#video-1"
                @click.prevent="scrollTo('#video-1')"
                class="skv-academy__banner-links_item"
              >
                <div class="skv-academy__banner-links_title">Смотреть</div>
                <div class="skv-academy__banner-links_link">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_156_1903)">
                      <rect
                        y="0.339478"
                        width="30"
                        height="30"
                        rx="15"
                        fill="#EBEFEE"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 9.33948L20 15.3395L14 21.3395"
                        fill="#830051"
                      />
                      <path
                        d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                        stroke="#830051"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_156_1903">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Модуль 1</span>
                </div>
              </a>
              <a
                href="#video-2"
                @click.prevent="scrollTo('#video-2')"
                class="skv-academy__banner-links_item"
              >
                <div class="skv-academy__banner-links_title">Смотреть</div>
                <div class="skv-academy__banner-links_link">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_156_1903)">
                      <rect
                        y="0.339478"
                        width="30"
                        height="30"
                        rx="15"
                        fill="#EBEFEE"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 9.33948L20 15.3395L14 21.3395"
                        fill="#830051"
                      />
                      <path
                        d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                        stroke="#830051"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_156_1903">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Модуль 2</span>
                </div>
              </a>
              <a
                href="#video-3"
                @click.prevent="scrollTo('#video-3')"
                class="skv-academy__banner-links_item"
              >
                <div class="skv-academy__banner-links_title">Смотреть</div>
                <div class="skv-academy__banner-links_link">
                  <svg
                    width="30"
                    height="31"
                    viewBox="0 0 30 31"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_156_1903)">
                      <rect
                        y="0.339478"
                        width="30"
                        height="30"
                        rx="15"
                        fill="#EBEFEE"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14 9.33948L20 15.3395L14 21.3395"
                        fill="#830051"
                      />
                      <path
                        d="M14 9.33948L20 15.3395L14 21.3395V9.33948Z"
                        stroke="#830051"
                        stroke-width="2"
                        stroke-linecap="square"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_156_1903">
                        <rect
                          y="0.339478"
                          width="30"
                          height="30"
                          rx="15"
                          fill="white"
                        />
                      </clipPath>
                    </defs>
                  </svg>
                  <span>Модуль 3</span>
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__info">
      <div class="content">
        <div class="skv-academy__info-container">
          <div class="skv-academy__info-head">
            <div class="skv-academy__info-title">3 модуля</div>
            <div class="skv-academy__info-text">
              с записями вебинаров цикла «Академия СКВ»
            </div>
          </div>
          <div class="skv-academy__info-line"></div>
          <div class="skv-academy__info-body">
            Ведущие ревматологи России ответят на основные вопросы, связанные с
            ведением пациентов с СКВ — от ранней диагностики до современной
            терапии:
            <ul class="skv-academy__info-body_list">
              <li>Каковы современные критерии постановки диагноза СКВ?</li>
              <li>
                Алгоритмы выбора терапии согласно стратегии «Лечения до
                достижения цели».
              </li>
              <li>
                Особенности назначения ГИБП с практическим разбором клинических
                случаев.
              </li>
            </ul>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__speakers">
      <div class="content">
        <div class="skv-academy__speakers-title">Спикеры «Академии СКВ»</div>
        <div class="skv-academy__speakers-row">
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-1.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-tag">Модератор:</div>
              <div class="skv-academy__speakers-name">
                Насонов Евгений Львович
              </div>
              <div class="skv-academy__speakers-job">
                академик РАН, научный руководитель ФГБНУ НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-2.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-tag">Модератор:</div>
              <div class="skv-academy__speakers-name">
                Лила Александр Михайлович
              </div>
              <div class="skv-academy__speakers-job">
                д.м.н., профессор, заслуженный врач РФ, член-корреспондент РАН,
                главный внештатный специалист-ревматолог МЗ РФ, директор ФГБНУ
                НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-3.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Соловьев Сергей Константинович
              </div>
              <div class="skv-academy__speakers-job">
                профессор, д.м.н., руководитель Центра инновационной
                ревматологии, главный врач-ревматолог МЕДСИ
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-4.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Попкова Татьяна Валентиновна
              </div>
              <div class="skv-academy__speakers-job">
                д.м.н., заведующая лабораторией системных ревматических
                заболеваний ФГБНУ НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-5.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Панафидина Татьяна Александровна
              </div>
              <div class="skv-academy__speakers-job">
                к.м.н., старший научный сотрудник лаборатории системных
                ревматических заболеваний ФГБНУ НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-6.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Решетняк Татьяна Магомедалиевна
              </div>
              <div class="skv-academy__speakers-job">
                д.м.н., заведующая лабораторией тромбовоспаления ФГБНУ НИИР им.
                В.А. Насоновой
              </div>
            </div>
          </div>
          <div class="skv-academy__speakers-item">
            <div class="skv-academy__speakers-img">
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/speaker-7.jpg`"
                alt=""
              />
            </div>
            <div class="skv-academy__speakers-info">
              <div class="skv-academy__speakers-name">
                Асеева Елена Александровна
              </div>
              <div class="skv-academy__speakers-job">
                д.м.н., ведущий научный сотрудник лаборатории системной красной
                волчанки ФГБНУ НИИР им. В.А. Насоновой
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__clin-cases">
      <div class="content">
        <div class="skv-academy__clin-cases-title">Клинические случаи</div>
        <div class="skv-academy__clin-cases-description">
          18 клинических случаев, описанных ведущими ревматологами России.
        </div>
        <div class="skv-academy__clin-cases-block" v-show="!currentClinCase">
          <div class="skv-academy__clin-cases-block-title">
            РУБРИКАТОР КЛИНИЧЕСКИХ СЛУЧАЕВ
          </div>
          <div class="skv-academy__clin-cases-block-content">
            <div class="skv-academy__clin-cases-block-variants">
              <div class="skv-academy__clin-cases-block-variant">
                <div class="skv-academy__clin-cases-block-variant-label">
                  <div class="skv-academy__clin-cases-block-variant-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/icon1.svg`"
                      alt=""
                    />
                  </div>
                  <div class="skv-academy__clin-cases-block-variant-title">
                    Достижение и удержание ремиссии / LLDAS
                  </div>
                </div>
                <div class="skv-academy__clin-cases-block-variant-items">
                  <div
                    @click="choiceClinCase(i)"
                    class="skv-academy__clin-cases-block-variant-item"
                    v-for="(i, ind) in clinicalCases[0]"
                    :key="ind"
                  >
                    {{ i }}
                  </div>
                </div>
              </div>
              <div class="skv-academy__clin-cases-block-variant">
                <div class="skv-academy__clin-cases-block-variant-label">
                  <div class="skv-academy__clin-cases-block-variant-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/icon2.svg`"
                      alt=""
                    />
                  </div>
                  <div class="skv-academy__clin-cases-block-variant-title">
                    Снижение дозы ГК
                  </div>
                </div>
                <div class="skv-academy__clin-cases-block-variant-items">
                  <div
                    @click="choiceClinCase(i)"
                    class="skv-academy__clin-cases-block-variant-item"
                    v-for="(i, ind) in clinicalCases[1]"
                    :key="ind"
                  >
                    {{ i }}
                  </div>
                </div>
              </div>
              <div class="skv-academy__clin-cases-block-variant">
                <div class="skv-academy__clin-cases-block-variant-label">
                  <div class="skv-academy__clin-cases-block-variant-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/icon3.svg`"
                      alt=""
                    />
                  </div>
                  <div class="skv-academy__clin-cases-block-variant-title">
                    Разнообразие клинических проявлений
                  </div>
                </div>
                <div class="skv-academy__clin-cases-block-variant-items">
                  <div
                    @click="choiceClinCase(i)"
                    class="skv-academy__clin-cases-block-variant-item"
                    v-for="(i, ind) in clinicalCases[2]"
                    :key="ind"
                  >
                    {{ i }}
                  </div>
                </div>
              </div>
              <div class="skv-academy__clin-cases-block-variant">
                <div class="skv-academy__clin-cases-block-variant-label">
                  <div class="skv-academy__clin-cases-block-variant-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/icon4.svg`"
                      alt=""
                    />
                  </div>
                  <div class="skv-academy__clin-cases-block-variant-title">
                    Быстрое начало действия
                  </div>
                </div>
                <div class="skv-academy__clin-cases-block-variant-items">
                  <div
                    @click="choiceClinCase(i)"
                    class="skv-academy__clin-cases-block-variant-item"
                    v-for="(i, ind) in clinicalCases[3]"
                    :key="ind"
                  >
                    {{ i }}
                  </div>
                </div>
              </div>
              <div class="skv-academy__clin-cases-block-variant">
                <div class="skv-academy__clin-cases-block-variant-label">
                  <div class="skv-academy__clin-cases-block-variant-icon">
                    <img
                      :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/icon5.svg`"
                      alt=""
                    />
                  </div>
                  <div class="skv-academy__clin-cases-block-variant-title">
                    Неэффективность / непереносимость комбинированной базисной
                    терапии
                  </div>
                </div>
                <div class="skv-academy__clin-cases-block-variant-items">
                  <div
                    @click="choiceClinCase(i)"
                    class="skv-academy__clin-cases-block-variant-item"
                    v-for="(i, ind) in clinicalCases[4]"
                    :key="ind"
                  >
                    {{ i }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="skv-academy__clin-cases-detail" v-show="currentClinCase">
          <div
            class="skv-academy__clin-cases-detail-back"
            @click="choiceClinCase(null)"
          >
            <svg
              width="25"
              height="25"
              viewBox="0 0 25 25"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M11.5 20L4 12.5L11.5 5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
              <path
                d="M5.5 12.5L20.5 12.5"
                stroke="currentColor"
                stroke-width="1.5"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <img
            :srcset="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/clin-case/${currentClinCase}.jpg 2x`"
          />
        </div>
      </div>
    </section>
    <section class="skv-academy__materials">
      <div class="content">
        <div class="skv-academy__materials-title">Материалы по теме</div>
        <div class="skv-academy__materials-tabs">
          <div
            class="skv-academy__materials-tab"
            @click="choiceTab('clinical_cases')"
          >
            Клинические случаи
          </div>
          <div
            class="skv-academy__materials-tab"
            @click="choiceTab('clinical_recommendations')"
          >
            Клинические рекомендации
          </div>
          <div
            class="skv-academy__materials-tab"
            @click="choiceTab('video')"
            v-if="false"
          >
            Видео
          </div>
        </div>
        <div class="skv-academy__slider-container">
          <swiper
            :key="currentSlides"
            class="skv-academy__slider-slider"
            v-bind="swiperSettings"
          >
            <swiper-slide
              v-for="(slide, ind) in slides[currentSlides]"
              :key="ind"
            >
              <Material :item="slide" />
            </swiper-slide>
          </swiper>
          <div class="skv-academy__slider-arrows">
            <div
              class="skv-academy__slider-arrow skv-academy__slider-arrow_left"
            >
              <svg
                width="24"
                height="25"
                viewBox="0 0 24 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.25 5L3.75 12.5L11.25 20"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M5.25 12.5L20.25 12.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
            <div
              class="skv-academy__slider-arrow skv-academy__slider-arrow_right"
            >
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M12.75 4.5L20.25 12L12.75 19.5"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
                <path
                  d="M18.75 12L3.75 12"
                  stroke="white"
                  stroke-width="1.5"
                  stroke-linecap="square"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__calculators">
      <div class="content">
        <div class="skv-academy__calculators-title">Калькуляторы</div>
        <div class="skv-academy__calculators-row">
          <div class="skv-academy__calculators-block">
            <picture
              ><source
                :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/calc1_mob.jpg
              `"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/calc1.jpg
              `"
                media="(max-width: 1220px)"
              />
              <source
                :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/calc1.jpg
              `"
                media="(min-width: 1221px)"
              />
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/calc1.jpg`"
              />
            </picture>
            <div class="skv-academy__calculators-block-text">
              <div class="skv-academy__calculators-block-title">SLEDAI-2K</div>
              <a
                href="https://az-most.ru/skv_index_calculator?tab=0"
                target="_blank"
                class="button button_pink"
              >
                Подробнее
              </a>
            </div>
          </div>
          <div class="skv-academy__calculators-block">
            <picture
              ><source
                :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/calc2_mob.jpg
              `"
                media="(max-width: 767px)"
              />
              <source
                :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/calc2.jpg
              `"
                media="(max-width: 1220px)"
              />
              <source
                :srcset="`
                https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/calc2.jpg
              `"
                media="(min-width: 1221px)"
              />
              <img
                :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/calc2.jpg`"
              />
            </picture>
            <div class="skv-academy__calculators-block-text">
              <div class="skv-academy__calculators-block-title">
                ИП SLICC/ACR
              </div>
              <a
                href="https://az-most.ru/skv_index_calculator?tab=1"
                target="_blank"
                class="button button_pink"
              >
                Подробнее
              </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__initiation">
      <div class="content">
        <div class="skv-academy__initiation-block">
          <div class="skv-academy__initiation-title">
            <span style="color: #b0d649">Инициация САФНЕЛО<sup>®</sup></span
            >: Когда, кому <span style="color: #b0d649">и</span> почему
          </div>
          <div class="skv-academy__initiation-row">
            <div class="skv-academy__initiation-item">
              <div class="skv-academy__initiation-item-img-container">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/initiation1.jpg`"
                />
              </div>
              <div class="skv-academy__initiation-item-title">
                Когда ГХХ недостаточно
              </div>
              <div class="skv-academy__initiation-item-description">
                Позиция EULAR: предшествующее лечение ИД не является
                обязательным для старта ГИБП, если СТ ГХХ (или ГК+ГХХ)
                неэффективна<sup>1</sup>
              </div>
              <a target="_blank" :href="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/Saphnelo_1224_PatientProfile_Anna%20A4-2%20port%20opt2.pdf`" class="button button_pink mt-6 mx-auto"> Скачать </a>
            </div>
            <div class="skv-academy__initiation-item">
              <div class="skv-academy__initiation-item-img-container">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/initiation2.jpg`"
                />
              </div>
              <div class="skv-academy__initiation-item-title">
                Когда ГК в дозе 5 мг/сут недостаточно
              </div>
              <div class="skv-academy__initiation-item-description">
                Позиция EULAR: поддерживающая доза ГК должна быть снижена до ⩽ 5
                мг/сут<sup>1</sup>
              </div>

              <a target="_blank" :href="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/Saphnelo_1224_PatientProfile_Isabella%20A4-2%20port.pdf`" class="button button_pink mt-6 mx-auto"> Скачать </a>
            </div>
            <div class="skv-academy__initiation-item">
              <div class="skv-academy__initiation-item-img-container">
                <img
                  :src="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/initiation3.jpg`"
                />
              </div>
              <div class="skv-academy__initiation-item-title">
                Когда есть признаки прогрессирования заболевания
              </div>
              <div class="skv-academy__initiation-item-description">
                Позиция EULAR: целью лечения СКВ является РЕМИССИЯ. Ее
                достижение улучшает клинические исходы<sup>1</sup>
              </div>
              <a target="_blank" :href="`https://storage.yandexcloud.net/${$root.backetName}/media/content/skv-academy/Saphnelo_1124_PatientProfile_Natasha%20A4-2%20port.pdf`"  class="button button_pink mt-6 mx-auto"> Скачать </a>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__video">
      <div class="content">
        <div class="skv-academy__video-first">
          <div class="skv-academy__video-head">
            <div class="skv-academy__video-head_mod">Модуль 1.</div>
            <div class="skv-academy__video-head_text">
              СКВ — стратегия и тактика. Современные взгляды на диагностику
              и классификационные критерии СКВ
            </div>
          </div>
          <div class="skv-academy__video-player">
            <div class="skv-academy__video-player-dropdown-container">
              <InfoDrop
                class="skv-academy__video-player_drop"
                :active="true"
                @openInner="openInner"
                :item="{
                  title: 'Программа',
                  content: FirstContent.description,
                  content_above: FirstContent.video,
                }"
              />
            </div>
          </div>
        </div>
        <div class="skv-academy__video-second">
          <div class="skv-academy__video-head">
            <div class="skv-academy__video-head_mod">Модуль 2.</div>
            <div class="skv-academy__video-head_text">
              Особенности терапии пациентов с СКВ. Вопросы стандартной терапии
              и бремени длительного применения глюкокортикостероидов,
              возможности и особенности применения ГИБП с практическим разбором
              клинических случаев
            </div>
          </div>
          <div class="skv-academy__video-player">
            <div class="skv-academy__video-player-dropdown-container">
              <InfoDrop
                class="skv-academy__video-player_drop"
                @openInner="openInner"
                :active="true"
                :item="{
                  title: 'Программа',
                  content: SecondContent.description,
                  content_above: SecondContent.video,
                }"
              />
            </div>
          </div>
        </div>
        <div class="skv-academy__video-third mt-10">
          <div class="skv-academy__video-head">
            <div class="skv-academy__video-head_mod">Модуль 3.</div>
            <div class="skv-academy__video-head_text">
              Рекомендации EULAR 2023 по СКВ. Реальная клиническая практика
              Сафнело® (анифролумаба)
            </div>
          </div>
          <div class="skv-academy__video-player">
            <div class="skv-academy__video-player-dropdown-container">
              <InfoDrop
                class="skv-academy__video-player_drop"
                @openInner="openInner"
                :active="true"
                :item="{
                  title: 'Программа',
                  content: ThirdContent.description,
                  content_above: ThirdContent.video,
                }"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="skv-academy__btn mt-10" v-if="false">
      <div class="content">
        <div class="skv-academy__subscribe button button_pink px-4">
          Подписаться на уведомления о новых записях
        </div>
      </div>
    </section>
    <section class="skv-academy__abbs">
      <div class="content">
        <div class="skv-academy__speakers-title mb-8" style="color: #0d2943">
          Список сокращений
        </div>
        СКВ – системная красная волчанка;
        <br /><br />
        ФГБОУ ВО – Федеральное государственное бюджетное образовательное
        учреждение высшего образования;
        <br /><br />
        РОО – региональная общественная организация;
        <br /><br />
        МЗ РФ – Министерство здравоохранения Российской Федерации;
        <br /><br />
        РАН – Российская академия наук;
        <br /><br />
        ГИБП – генно-инженерный биологический препарат;
        <br /><br />
        ФГБНУ НИИР – Федеральное государственное бюджетное научное учреждение
        "Научно-исследовательский институт ревматологии";
        <br /><br />
        ГКС – глюкокортикостероиды.
      </div>
    </section>
    <section class="skv-academy__abbs">
      <div class="content">
        <InfoDrop
          :active="true"
          :item="{
            title: `<span style='color: #0d2943'>Источники</span>`,
            content: sources,
          }"
        />
      </div>
    </section>
  </main>
</template>

<script>
import InfoDrop from "../../components/pageComponents/InfoDrop.vue";
import Material from "../../components/pageComponents/SkvAcademyMaterial.vue";
import "swiper/swiper-bundle.css";
import { Navigation } from "swiper";
import { SwiperCore, Swiper, SwiperSlide } from "swiper-vue2";
import { Fancybox } from "@fancyapps/ui";
import "@fancyapps/ui/dist/fancybox/fancybox.css";
SwiperCore.use([Navigation]);
export default {
  name: "SkvAcademy",
  components: {
    InfoDrop,
    Material,
    Swiper,
    SwiperSlide,
  },
  data: (vm) => ({
    items: [],
    index: null,
    currentClinCase: null,
    swiperSettings: {
      slidesPerView: 4,
      navigation: {
        nextEl: ".skv-academy__slider-arrow_right",
        prevEl: ".skv-academy__slider-arrow_left",
      },
      breakpoints: {
        // when window width is >= 320px
        320: {
          slidesPerView: 1,
          spaceBetween: 32,
         
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 32,
         
        },
        1220: {
          slidesPerView: 4,
          spaceBetween: 32,
         
        },
        // when window width is >= 640px
      },
    },
    currentSlides: "clinical_cases",
  }),
  computed: {
    loadedRefs() {
      return this.$refs;
    },
    FirstContent() {
      return {
        id: 1,
        content: [
          {
            active: true,
          },
        ],
        video: `<video
            controls
            class="skv-video"
            id="video-1"
            src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/Академия-СКВ_часть-1-min.mp4"
            poster="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/video-1.jpg"
          ></video>`,
        description: `
          <div class="skv-academy timecodes">
            <div class="timecodes__body">
              <div class="timecodes__row">
                <div class="timecodes__theme">Проблемы и вызовы СКВ</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Попкова Татьяна Валентиновна</div>
                    <div class="timecodes__time">00:07:08</div>
                  </div>
                </div>
              </div>
              <div class="timecodes__row">
                <div class="timecodes__theme">Стратегия лечения до достижения цели при СКВ</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Соловьев Сергей Константинович</div>
                    <div class="timecodes__time">00:22:20</div>
                  </div>
                </div>
              </div>
              <div class="timecodes__row">
                <div class="timecodes__theme">Классификационные критерии СКВ и мониторинг пациентов на амбулаторном этапе</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Решетняк Татьяна Магомедалиевна</div>
                    <div class="timecodes__time">00:44:00</div>
                  </div>
                </div>
              </div>
              <div class="timecodes__row">
                <div class="timecodes__theme">Неполная СКВ: ранняя диагностика и подходы к ведению пациентов</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Панафидина Татьяна Александровна</div>
                    <div class="timecodes__time">01:04:11</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `,
      };
    },
    SecondContent() {
      return {
        id: 2,
        content: [
          {
            active: true,
          },
        ],
        video: `<video
            controls
            class="skv-video"
            id="video-2"
            src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/Академия-СКВ_часть-2-min.mp4"
            poster="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/video-2.jpg"
          ></video>`,
        description: `
          <div class="skv-academy timecodes">
            <div class="timecodes__body">
              <div class="timecodes__row">
                <div class="timecodes__theme">Современные алгоритмы применения стандартной терапии СКВ</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Попкова Татьяна Валентиновна</div>
                    <div class="timecodes__time">00:07:28</div>
                  </div>
                </div>
              </div>
              <div class="timecodes__row">
                <div class="timecodes__theme">Глюкокортикоиды в лечении СКВ: аргументы за и против?</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Асеева Елена Александровна</div>
                    <div class="timecodes__time">00:22:55</div>
                  </div>
                </div>
              </div>
              <div class="timecodes__row">
                <div class="timecodes__theme">Место ГИБП в терапии пациентов с СКВ</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Решетняк Татьяна Магомедалиевна</div>
                    <div class="timecodes__time">00:39:38</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `,
      };
    },
    ThirdContent() {
      return {
        id: 3,
        content: [
          {
            active: true,
          },
        ],
        video: `<video
            controls
            class="skv-video"
            id="video-3"
            src="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/module3.mp4"
            poster="https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/video-3.jpg"
          ></video>`,
        description: `
          <div class="skv-academy timecodes">
            <div class="timecodes__body">
              <div class="timecodes__row">
                <div class="timecodes__theme">Вступительное слово председателя</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Председатель: Насонов Евгений Львович, д.м.н., профессор, академик РАН, президент Ассоциации ревматологов России</div>
                    <div class="timecodes__time">00:00:25</div>
                  </div>
                </div>
              </div>
              <div class="timecodes__row">
                <div class="timecodes__theme">Что нового в терапии СКВ в 2023 году</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Попкова Татьяна Валентиновна, д.м.н., заведующая лабораторией системных ревматических заболеваний ФГБНУ НИИР им. В.А. Насоновой</div>
                    <div class="timecodes__time">00:04:34</div>
                  </div>
                </div>
              </div>
              <div class="timecodes__row">
                <div class="timecodes__theme">Критерии диагностики и цели терапии СКВ</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Решетняк Татьяна Мегомедалиевна, д.м.н., заведующая лабораторией тромбовоспаления ФГБНУ НИИР им. В.А. Насоновой</div>
                    <div class="timecodes__time">00:29:45</div>
                  </div>
                </div>
              </div>
              <div class="timecodes__row">
                <div class="timecodes__theme">Локальный опыт применения ингибитора ИНФ типа I у пациентов с СКВ</div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Асеева Елена Александровна, д.м.н., ведущий научный сотрудник лаборатории системной красной волчанки ФГБНУ НИИР им. В.А. Насоновой</div>
                    <div class="timecodes__time">00:50:54</div>
                  </div>
                </div>
              </div>
              <div class="timecodes__row">
                <div class="timecodes__theme">Пациент с разнообразными клиническими проявлениями СКВ </div>
                <div>
                  <div class="timecodes__lectors-row">
                    <div class="timecodes__lector">Асеева Елена Александровна, д.м.н., ведущий научный сотрудник лаборатории системной красной волчанки ФГБНУ НИИР им. В.А. Насоновой</div>
                    <div class="timecodes__time">01:05:17</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        `,
      };
    },
    sources() {
      return `
      <ol>
        <li>Fanouriakis A, Kostopoulou M, Andersen J, et al. EULAR recommendations for the management of systemic lupus erythematosus: 2023 update. Ann Rheum Dis. 2024;83(1):15-29</li>
      </ol>
      `;
    },
    clinicalCases() {
      return [
        [3, 4, 5, 9, 10, 11, 12, 15],
        [1, 3, 4, 5, 10, 11, 12, 13, 14, 15, 17, 18],
        [2, 7, 8, 13, 17, 18],
        [1, 2, 5, 6, 8, 11, 12, 13, 16],
        [2, 14],
      ];
    },
    slides() {
      return {
        clinical_cases: [
          {
            name: "Клиническое наблюдение пациента с высокой активностью системной красной волчанки",
            link: "/library/textmaterials/klinicheskoe-nablyudenie-pacienta-s-vysokoj-aktivnostyu-sistemnoj-krasnoj-volchanki",
            nosology: [],
            disease: [
              {
                title: "Системная красная волчанка",
              },
            ],
            type: {
              name: "Клинические случаи",
            },
            announce:
              "https://storage.yandexcloud.net/sb-prod-most-static-01/media/uploads/materials/announce/2022/10/27/cq5dam.thumbnail.319.319_12.png",
          },
          {
            name: "Клинический случай системной красной волчанки с синдромом Рейно, перикардитом и высоким титром антинуклеарного фактора. Подозрение на наследственный семейный амилоидоз без невропатии (Средиземноморская лихорадка)",
            link: "/library/textmaterials/klinicheskij-sluchaj-sistemnoj-krasnoj-volchanki-s-sindromom-rejno-perikarditom-i-vysokim-titrom-anf-podozrenie-na-nasledstvennyj-semejnyj-amiloidoz-bez-nevropatii-sredizemnomorskaya-lihoradka",
            nosology: [],
            disease: [
              {
                title: "Системная красная волчанка",
              },
            ],
            type: {
              name: "Клинические случаи",
            },
            announce:
              "https://storage.yandexcloud.net/sb-prod-most-static-01/media/uploads/materials/announce/2022/10/28/cq5dam.thumbnail.319.319_3.png",
          },
          {
            name: "Системная красная волчанка с резистентными кожными проявлениями",
            link: "/library/textmaterials/sistemnaya_krasnaya_volchanka_s_rezistentnymi_kozhnymi_proyavleniyami",
            nosology: [],
            disease: [
              {
                title: "Системная красная волчанка",
              },
            ],
            type: {
              name: "Клинические случаи",
            },
            announce:
              "https://storage.yandexcloud.net/sb-prod-most-static-01/media/uploads/materials/announce/2023/02/16/img_1.jpg",
          },
          {
            name: "Системная красная волчанка с частыми обострениями",
            link: "/library/textmaterials/sistemnaya-krasnaya-volchanka-s-chastymi-obostreniyami",
            nosology: [],
            disease: [
              {
                title: "Системная красная волчанка",
              },
            ],
            type: {
              name: "Клинические случаи",
            },
            announce:
              "https://storage.yandexcloud.net/sb-prod-most-static-01/media/uploads/materials/announce/2023/02/16/img_2.jpg",
          },
        ],
        clinical_recommendations: [
          {
            name: "Министерство здравоохранения Российской Федерации",
            link: "https://cr.minzdrav.gov.ru/clin-rec",
            nosology: [],
            disease: [],
            type: {
              name: "Клинические рекомендации",
            },
            announce:
              `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/card1.jpg`,
          },
          {
            name: "Клиническое наблюдение пациента с высокой активностью системной красной волчанки",
            link: "https://ard.bmj.com/content/83/1/15",
            nosology: [],
            disease: [],
            type: {
              name: "Клинические рекомендации",
            },
            announce:
              `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/card2.jpg`,
          },
        ],
        video: [
          {
            name: "EUROPEAN ALLIANCE OF ASSOCIATIONS FOR RHEUMATALOGY",
            link: "/library/textmaterials/klinicheskij-sluchaj-sistemnoj-krasnoj-volchanki-s-sindromom-rejno-perikarditom-i-vysokim-titrom-anf-podozrenie-na-nasledstvennyj-semejnyj-amiloidoz-bez-nevropatii-sredizemnomorskaya-lihoradka",
            nosology: [],
            disease: [
              {
                title: "Системная красная волчанка",
              },
            ],
            type: {
              name: "Клинические случаи",
            },
            announce:
              `https://storage.yandexcloud.net/sb-prod-most-static-01/media/uploads/materials/announce/2022/10/28/cq5dam.thumbnail.319.319_3.png`,
          },
        ],
      };
    },
  },
  methods: {
    scrollTo(selector) {
      const element = document.querySelector(selector);
      const y = element.getBoundingClientRect().top + window.scrollY;
      if (element) {
        window.scrollTo({
          behavior: "smooth",
          top: y - 300,
        });
      }
    },
    openInner() {
      if (typeof ym !== "undefined") {
        ym(91468266, "reachGoal", "see broadcast programm click", {
          "see broadcast programm click": {
            ...this.$root.ymFields,
          },
        });
      }
    },
    choiceClinCase(i) {
      if (!this.$root.isMobile && !this.$root.isTablet) {
        this.$set(this, "currentClinCase", i);
      } else {
        new Fancybox(
          [
            {
              src: `https://storage.yandexcloud.net/${this.$root.backetName}/media/content/skv-academy/clin-case/${i}.jpg`,
            },
          ],
          {}
        );
      }
    },
    choiceTab(t) {
      this.$set(this, "currentSlides", t);
    },
  },
  async mounted() {},
};
</script>

<style lang="scss" scoped>
section {
  margin-top: 90px;
  @media screen and(max-width: 1199px) {
    margin-top: 40px;
  }
}
.skv-academy {
  &__banner {
    margin-top: 0;
    &-top {
      position: relative;
      height: 668px;
      @media screen and(max-width: 1199px) {
        height: 400px;
      }
      @media screen and(max-width: 767px) {
        height: auto;
      }
      .content {
        height: 100%;
      }
    }
    &-container {
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      height: 100%;
      padding: 172px 0 40px;
      @media screen and(max-width: 1199px) {
        padding: 40px 0 55px;
      }
      @media screen and(max-width: 767px) {
        padding: 24px 0;
      }
    }
    &-text-container {
      max-width: 592px;
      color: #ffffff;
      @media screen and(max-width: 1199px) {
        max-width: 325px;
      }
      @media screen and(max-width: 767px) {
        max-width: none;
        width: 100%;
        color: #1f1f1f;
      }
    }
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 42px;
      line-height: 48px;
      font-weight: 400;
    }
    &-description {
      margin-top: 12px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 21px;
      line-height: 26px;
      font-weight: 400;
      @media screen and(max-width: 1199px) {
        font-size: 18px;
        line-height: 22px;
      }
    }
    &-links {
      margin-top: auto;
      &_row {
        display: flex;
        flex-direction: row;
        gap: 16px;
        @media screen and(max-width: 767px) {
          margin-top: 24px;
          justify-content: space-between;
          flex-wrap: wrap;
        }
      }
      &_item {
        width: 180px;
        display: flex;
        flex-direction: column;
        padding: 10px 10px 20px;
        background-color: #ffffff;
        border-radius: 5px;
        font-family: "Roboto", sans-serif;
        font-size: 14px;
        line-height: 20px;
        font-weight: 400;
        @media screen and(max-width: 1199px) {
          width: 150px;
        }
        @media screen and(max-width: 767px) {
          max-width: 163px;
          width: 100%;
        }
      }
      &_link {
        margin-top: 8px;
        display: flex;
        flex-direction: row;
        align-items: center;
        font-family: "Roboto Slab", sans-serif;
        font-size: 18px;
        line-height: 22px;
        font-weight: 400;
        color: #830051;
        span {
          margin-left: 8px;
        }
      }
      &_mob {
        display: none;
        @media screen and(max-width: 767px) {
          display: block;
        }
      }
      @media screen and(max-width: 767px) {
        display: none;
      }
    }
    &-image_container {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      @media screen and(max-width: 767px) {
        height: 220px;
        position: static;
      }
    }
    &-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  &__info {
    &-container {
      padding: 48px 40px;
      background-color: #ebefee;
    }
    &-head {
      display: flex;
      flex-direction: row;
      align-items: center;
      @media screen and(max-width: 1199px) {
        flex-direction: column;
        align-items: flex-start;
      }
    }
    &-title {
      margin-right: 36px;
      display: flex;
      flex-direction: row;
      font-family: "Roboto Slab", sans-serif;
      font-size: 42px;
      font-weight: 400;
      line-height: 48px;
      color: #830051;
    }
    &-text {
      padding-top: 5px;
      font-family: "Roboto", sans-serif;
      font-size: 23px;
      font-weight: 500;
      line-height: 29px;
      color: #3c4242;
    }
    &-line {
      margin: 34px 0;
      border-bottom: 1px solid #ffff;
    }
    &-body {
      max-width: 75%;
      font-family: "Roboto", sans-serif;
      font-size: 18px;
      font-weight: 400;
      line-height: 26px;
      color: #3c4242;
      @media screen and(max-width: 1199px) {
        max-width: none;
      }
      &_list {
        margin-top: 16px;
        list-style: disc;
        padding-left: 25px;
        font-weight: 500;
      }
    }
  }
  &__speakers {
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-size: 23px;
      font-weight: 400;
      line-height: 29px;
    }
    &-row {
      margin-top: 56px;
      padding-bottom: 64px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 32px 102px;
      @media screen and(max-width: 1199px) {
        margin-top: 40px;
        grid-template-columns: 1fr;
        padding-bottom: 0;
      }
    }
    &-item {
      width: 100%;
      display: grid;
      grid-template-columns: 140px 1fr;
      grid-gap: 24px;
      @media screen and(max-width: 767px) {
        grid-template-columns: 80px 1fr;
      }
    }
    &-img {
      height: 140px;
      width: 140px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
      @media screen and(max-width: 767px) {
        width: 80px;
        height: 80px;
      }
    }
    &-info {
      height: 140px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      @media screen and(max-width: 1199px) {
        height: auto;
      }
    }
    &-tag {
      margin-bottom: 8px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
      @media screen and(max-width: 767px) {
        font-size: 12px;
        line-height: 14px;
      }
    }
    &-name {
      font-family: "Roboto Slab", sans-serif;
      font-size: 18px;
      font-weight: 500;
      line-height: 26px;
      @media screen and(max-width: 767px) {
        font-size: 16px;
        line-height: 22px;
      }
    }
    &-job {
      margin-top: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      @media screen and(max-width: 767px) {
        margin-top: 8px;
        font-size: 14px;
        line-height: 20px;
      }
    }
  }
  &__clin-cases {
    &-title {
      margin-bottom: 16px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &-description {
      margin-bottom: 56px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #1f1f1f;
    }

    &-block {
      background: linear-gradient(
        257.79deg,
        #61aa62 0%,
        #30727d 7.6%,
        #1b2e38 23.77%,
        #1b2e38 45.35%,
        #265e76 55.73%,
        #1b2e38 65.23%
      );

      &-title {
        padding: 28px 48px;
        background-color: #0d2943;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 23px;
        line-height: 29px;
        color: #ffffff;
        @media screen and(max-width: 767px) {
          padding: 20px 24px;
          font-size: 18px;
        }
      }

      &-content {
        padding: 40px 30px;

        @media screen and(max-width: 1219px) {
          padding: 40px 20px;
        }

        @media screen and(max-width: 767px) {
          padding: 20px;
        }
      }

      &-variants {
        background-color: #fff;
        border-radius: 16px;
        padding: 50px 60px;
        @media screen and(max-width: 1219px) {
          padding: 30px 20px;
        }
      }

      &-variant {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        @media screen and(max-width: 1219px) {
          flex-direction: column;
          align-items: flex-start;
        }

        &:not(:last-child) {
          margin-bottom: 44px;
          @media screen and(max-width: 767px) {
            margin-bottom: 32px;
          }
        }

        &-label {
          width: fit-content;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          margin-right: 32px;
        }

        &-icon {
          margin-right: 16px;
          flex-shrink: 0;
        }

        &-title {
          font-family: "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 120%;
          color: #1f1f1f;
        }

        &-items {
          margin-left: auto;
          display: flex;
          justify-content: flex-start;
          align-items: center;
          gap: 5px;
          @media screen and(max-width: 1219px) {
            margin-left: 0;
            margin-top: 16px;
            flex-wrap: wrap;
          }
        }

        &-item {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          border: 1px solid #1b7183;
          font-family: "Roboto Slab", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 36px;
          text-align: center;
          color: #1b7183;
          cursor: pointer;
        }
      }
    }

    &-detail {
      position: relative;

      &-back {
        position: absolute;
        top: 15px;
        left: 15px;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #ebefee;
        text-align: center;
        color: #ebefee;
        cursor: pointer;
      }
    }
  }
  &__materials {
    &-title {
      margin-bottom: 32px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
    }

    &-tabs {
      margin-bottom: 24px;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      gap: 8px;
      @media screen and(max-width: 767px) {
        flex-wrap: wrap;
      }
    }

    &-tab {
      padding: 4px 12px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
      color: #830051;
      border: 1px solid #830051;
      width: fit-content;
      border-radius: 99px;
      cursor: pointer;
    }
  }
  &__slider {
    padding: 60px 0;
    @media screen and (max-width: 767px) {
      padding: 40px 0;
    }

    &-container {
      position: relative;
    }

    &-title {
      margin-bottom: 30px;
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 42px;
      line-height: 120%;
      color: #3c4242;
      @media screen and (max-width: 767px) {
        font-size: 32px;
      }
    }

    &-description {
      margin-bottom: 32px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #3c4242;
      @media screen and (max-width: 767px) {
        font-size: 16px;
      }
    }

    &-arrows {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: calc(100% + 40px);
      display: flex;
      justify-content: space-between;
      align-items: center;
      user-select: none;
      z-index: 2;

      @media screen and (max-width: 767px) {
        display: none;
      }
    }

    &-arrow {
      width: 40px;
      height: 40px;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      background-color: #830051;
      user-select: none;

      &.swiper-button-disabled {
        display: none;
      }
    }

    &-slider {
      & .swiper-slide {
        height: auto;
      }
    }
  }

  &__calculators {
    &-title {
      font-family: "Roboto Slab", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 23px;
      line-height: 29px;
      color: #1f1f1f;
      margin-bottom: 32px;
    }
    &-row {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 32px;
      @media screen and(max-width: 1219px) {
        grid-template-columns: 1fr;
      }
    }

    &-block {
      position: relative;

      & img {
        width: 100%;
      }

      &-text {
        position: absolute;
        top: 24px;
        left: 24px;
        z-index: 2;
      }

      &-title {
        margin-bottom: 20px;
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 24px;
        color: #3c4242;
      }
    }
  }
  &__initiation {
    &-block {
      border-radius: 5px;
      background: linear-gradient(
        85.99deg,
        #1b2e38 -3.19%,
        #1b2e38 21.01%,
        #3a7689 82.16%,
        #72d7a6 100.87%
      );
      padding: 50px 120px;

      @media screen and(max-width: 1219px) {
        padding: 50px 40px;
        background: linear-gradient(
          173.97deg,
          #1b2e38 -7.35%,
          #1b2e38 17.36%,
          #3a7689 79.79%,
          #72d7a6 98.9%
        );
      }

      @media screen and(max-width: 767px) {
        padding: 50px 24px;
      }
    }

    &-title {
      margin-bottom: 24px;
      font-family: "Roboto Slab", sans-serif;
      font-weight: 400;
      font-size: 32px;
      line-height: 48px;
      color: #fff;
      text-align: center;
      @media screen and(max-width: 767px) {
        font-size: 24px;
        line-height: 28px;
      }
    }

    &-row {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 32px;

      @media screen and(max-width: 1219px) {
        grid-template-columns: 1fr;
      }
      @media screen and(max-width: 767px) {
      }
    }

    &-item {
      margin-top: 77px;
      border-radius: 16px;
      background-color: #fff;
      padding: 0 30px 20px;
      display: flex;
      flex-direction: column;
      min-height: 350px;
      @media screen and(max-width: 1219px) {
        max-width: 290px;
        min-height: auto;
        margin: 77px auto 0;
      }

      &-img-container {
        width: 154px;
        height: 154px;
        border: 4px solid #b0d649;
        border-radius: 50%;
        overflow: hidden;
        margin: -77px auto 12px;

        & img {
          width: 100%;
        }
      }

      &-title {
        margin-bottom: 24px;
        font-family: "Roboto Slab", sans-serif;
        font-style: normal;
        font-weight: 600;
        font-size: 20px;
        line-height: 110%;
        text-align: center;
        color: #bb4889;
        min-height: 66px;
      }

      &-description {
        margin: 0 0 auto;
        font-family: "Roboto";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 140%;
        text-align: center;
        color: #1f1f1f;
        @media screen and(max-width: 1219px) {
          margin: 0 0 24px;
        }
      }
    }
  }
  &__video {
    &-second {
      margin-top: 40px;
    }
    &-player {
      &-dropdown-container {
        background-color: #ebefee;
      }
    }
    &-head {
      margin-bottom: -40px;
      padding: 28px 48px;
      display: flex;
      flex-direction: row;
      background-color: #0d2943;
      color: #ffffff;
      @media screen and(max-width: 1199px) {
        flex-direction: column;
        padding: 24px 32px;
      }
      @media screen and(max-width: 767px) {
        padding: 24px 16px;
      }
      &_mod {
        display: flex;
        width: 130px;
        margin-right: 40px;
        font-family: "Roboto", sans-serif;
        font-size: 23px;
        line-height: 29px;
        font-weight: 500;
        @media screen and(max-width: 1199px) {
          margin-right: 0;
          margin-bottom: 24px;
        }
        @media screen and(max-width: 767px) {
          font-size: 18px;
          line-height: 22px;
        }
      }
      &_text {
        width: fit-content;
        font-family: "Roboto Slab", sans-serif;
        font-size: 23px;
        font-weight: 400;
        line-height: 29px;
        @media screen and(max-width: 767px) {
          font-size: 21px;
          line-height: 26px;
        }
      }
    }
    &-player {
      &_drop {
        .info-item {
          padding: 20px;
        }
      }
    }
  }
  &__subscribe {
    white-space: normal;
    text-align: center;
    height: auto;
    padding: 12px 24px;

    @media screen and(max-width: 767px) {
      max-width: 100%;
      width: 100%;
    }
  }
  &__abbs {
    font-family: "Roboto", sans-serif;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #1f1f1f;
  }
}
</style>
<style lang="scss">
.skv-video {
  width: 100%;
  height: auto;
}
.skv-academy.timecodes {
  margin-top: 0;
  // background-color: #ebefee;
  .timecodes__row {
    grid-template-columns: 40% calc(60% - 32px);
    @media screen and(max-width: 767px) {
      grid-template-columns: 1fr;
      // grid-template-rows: repeat(2, 1fr);
    }
    .timecodes__lectors-row {
      grid-template-columns: 1fr auto;
      @media screen and(max-width: 1199px) {
        grid-template-columns: 1fr auto;
      }
      .timecodes__time {
        padding: 5px 10px;
        border-radius: 99px;
        background-color: #fff;
        line-height: 26px;
        color: #1f1f1f;
        text-decoration: none;
      }
    }
  }
}

.skv-academy {
  & .info-item__head {
    margin-top: 24px;
  }

  & .skv-academy__video-player_drop {
    & .info-item {
      padding: 0 28px;
    }
  }

  ol {
    list-style: decimal;
    padding-left: 0.75em;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #3c4242;
    @media screen and (max-width: 540px) {
      padding-left: 4%;
    }
  }
}
</style>
